<template>
  <div class="not-found-container">
    <div class="not-found-content">
      <h1 class="error-code">404</h1>
      <h2 class="error-title">페이지를 찾을 수 없습니다</h2>
      <p class="error-message">
        요청하신 페이지가 존재하지 않거나, 이동되었거나, 사용할 수 없는 상태입니다.
      </p>
      <div class="action-buttons">
        <button class="back-button" @click="goBack">이전 페이지로</button>
        <button class="home-button" @click="goHome">홈으로 이동</button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NotFound',
  methods: {
    goBack() {
      this.$router.go(-1);
    },
    goHome() {
      this.$router.push('/');
    }
  }
}
</script>

<style scoped>
.not-found-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  text-align: center;
  background-color: #f8f9fa;
}

.not-found-content {
  max-width: 600px;
  padding: 2rem;
  background-color: white;
  border-radius: 8px;
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.error-code {
  font-size: 6rem;
  margin: 0;
  color: #070707;
  line-height: 1;
}

.error-title {
  font-size: 2rem;
  margin: 1rem 0;
  color: #343a40;
}

.error-message {
  font-size: 1.1rem;
  margin-bottom: 2rem;
  color: #6c757d;
  line-height: 1.5;
}

.action-buttons {
  display: flex;
  justify-content: center;
  gap: 1rem;
}

button {
  padding: 0.75rem 1.5rem;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s;
}

.back-button {
  background-color: #645f5b;
    color: #fff;
}

.back-button:hover {
  background-color: #5a6268;
}

.home-button {
  background-color: #ff7300;
    color: #fff;
}

.home-button:hover {
  background-color: #0069d9;
}
</style>